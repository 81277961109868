import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import {IntlProvider, addLocaleData} from "react-intl";
// import translations from "./i18n/locales";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './Styles/index.css'

const AppWrapper = lazy(() =>
  import(/* webpackChunkName: "appWrapper" */ "./appWrapper")
);


ReactDOM.render(
  <IntlProvider>
    <Suspense fallback={null}>
      <AppWrapper/>
    </Suspense>
  </IntlProvider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
